import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

export default function Splash() {
  const [img,setImg] = useState('/logo1.png')
  const [showSplash, setShowSplash] = useState(true);
  const navigate = useNavigate();
  const imgg = useRef(null);
  useGSAP(()=>{
    const tl = gsap.timeline()
    tl.from('.img',{
      scale:0,
      opacity: 0,
      y: '-85vh',
      duration: 1,
      onComplete: ()=>{
        setImg('/logo_blink.png')
      }
    })
    tl.to('.img',{
      duration:0.3,
      onComplete: ()=>{
        setImg('/logo1.png')
      }
    })
    tl.to('.img',{
      duration:0.3,
      onComplete: ()=>{
        setImg('/logo_blink.png')
      }
    })
    tl.to('.img',{
      duration:0.3,
      onComplete: ()=>{
        setImg('/logo1.png')
      }
    })
    tl.to('.img',{
      opacity:0,
      duration:1.3,
      y:'-85vh',
      onComplete:()=>{
        setShowSplash(false);
        navigate('/home')
      }
    })
    return () => tl.kill();
  })

  return (
    <div className='bg-b cursor-cc'>
      {showSplash && (
        <div className={`flex items-center flex-col justify-center bg-p text-center text-[100px] font-semibold h-[calc(100vh)]`}>
          <img src={img} alt='logo' className='h-[50vw] w-auto img' ref={imgg}/>
        </div>
      )}
    </div>
  );
}
