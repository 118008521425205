import { useState } from "react";

const Bolt = ({mousePosition, setMousePosition}) => {
    const [isMouseOver, setIsMouseOver] = useState(false);
    const handleMouseLeave = () => {
        setIsMouseOver(false);
    };
    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
        const x = ((e.clientX - left) / width) * 100;
        const y = ((e.clientY - top) / height) * 100;
        setMousePosition({ x, y });
        setIsMouseOver(true);
    };
    return ( 
      <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#0D0D0D"
        viewBox="0 0 24 24"
        strokeWidth={0.13}
        stroke="currentColor"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        width={'100%'}
        height={'100%'}
      >
        <defs>
          <radialGradient id="dynamicRadialGrad" cx={`${mousePosition.x}%`} cy={`${mousePosition.y}%`} r="60%">
            <stop offset="0%" stopColor="yellow" />
            <stop offset="100%" stopColor="#0D0D0D" />
          </radialGradient>
        </defs>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
          stroke={isMouseOver ? "url(#dynamicRadialGrad)" : "#0D0D0D"} // Conditionally apply the radial gradient or black stroke
        />
      </svg>
      </div>
     );
}

export default Bolt;