import React from 'react';
import './index.css';
import App from './App';
import Navigation from './Nav';
import Footer from './Footer';
import Animation from './animate';
import Info from './Info';
import WhyChoose from './Choose';
import Tools from './Tools';
import Quote from './Quote';


export default function New() {
  return (
    <div className='w-full overflow-hidden'>
      <div className='cursor-cc w-full'>
        <div className={` z-50 fixed shadow-black shadow-md top-0 w-full`}>
          <Navigation />
        </div>
        <div className='w-full'>
          <App />
        </div>
        <div className='bg-b w-full '>
          <div className="w-full">
            <Info />
          </div>
          <hr className='sm:hidden block'/>
          <Quote />
          <hr className='sm:hidden block'/>
          <div className='sm:block hidden h-[400vh] w-full'>
            <Animation />
          </div>
          <div className='w-full'>
            <WhyChoose />
          </div>
          <hr />
          <div className='w-full'>
            <Tools />
          </div>
        </div>
        <div className="w-full bg-p bottom-0">
          <Footer />
        </div>
      </div>
    </div>
  );
}