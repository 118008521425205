import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import SplitType from "split-type";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Bolt from "./Bolt";
import Fire from "./Fire";
// Register the GSAP plugin (useGSAP is not a plugin but a hook, so this line can be omitted)
gsap.registerPlugin(ScrollTrigger);

export default function Footer() {
  const [mousePosition, setMousePosition] = useState({x:0, y:0})
  const foot = useRef();
  const email = 'shafiqueabdurrehman1@gmail.com';
  const subject = 'Web Development';
  useGSAP(() => {
    gsap.fromTo(foot.current,{x: '-65vw'},{
        x: '63vw',
        repeat: -1,
        ease: 'linear',
        duration: 20
    })
  });
  useEffect(() => {
    const text = new SplitType('.contact');

    const tl = gsap.timeline();
    const chars = text.chars;

    if (chars.length > 0) {
      gsap.set(".contact", { perspective: 400 });
      tl.from(chars, {
        scrollTrigger: {
          trigger: '.footer',
          start: 'top bottom',
          end: '+=10%',
        },
        duration: 0.8,
        opacity: 0,
        scale: 0,
        rotationX: 180,
        transformOrigin: "0% 50% -50",
        ease: "back",
        stagger: 0.01
      });
    }
  },[]);

  return (
    <footer className="flex flex-col items-center sm:h-[20vw] h-[20vh] footer relative w-full">
      <div className="contact"
      >
        <span className="text-white sm:text-[2vw] text-[2.5vh] sm:mt-[0.5vw] mt-[1vh]">Contact Me:</span>
      </div>
      <div className="flex-grow flex flex-row sm:space-x-[2vw] space-x-[5vw] w-full items-center justify-center">
        <a href="https://www.linkedin.com/in/abd-ur-rehman-shafique-54788b245" target="_blank" rel="noopener noreferrer">
          <img src="/link2.png" alt="linkedin" className="sm:size-[3.5vw] size-[6vh] rounded-[1vw] hover:cursor-custom transform active:scale-90 hover:size-[4vw] transition-all duration-300 shadow-xl"/>
        </a>
        <a href="https://www.instagram.com/abdurrehman.shafique/?hl=en" target="_blank" rel="noopener noreferrer">
          <img src="/insta.webp" alt="instagram" className="sm:size-[3.5vw] size-[6vh] rounded-[1vw] hover:cursor-custom transform active:scale-90 hover:size-[4vw] transition-all duration-300 shadow-xl"/>
        </a>
        <a href="https://github.com/AbdurRehman-eng/Projects.git" target="_blank" rel="noopener noreferrer">
          <img src="/github.jpg" alt="github" className="sm:size-[3.5vw] size-[6vh] rounded-[1vw] hover:cursor-custom transform active:scale-90 hover:size-[4vw] transition-all duration-300 shadow-xl"/>
        </a>
        <a href={`mailto:${email}?subject=${encodeURIComponent(subject)}`}>
          <img src="/mail3.jpg" alt="mail" className="sm:size-[3.5vw] size-[6vh] rounded-[1vw] hover:cursor-custom transform active:scale-90 hover:size-[4vw] transition-all duration-300 shadow-xl"/>
        </a>
        <div className="absolute left-[7vw] top-[0vh] size-[18vw]">
            <Bolt setMousePosition={setMousePosition} mousePosition={mousePosition}/>
        </div>
        <div className="absolute right-[7vw] top-[0vh] size-[18vw]">
            <Fire setMousePosition={setMousePosition} mousePosition={mousePosition}/>
        </div>
      </div>
      <div className="sm:h-[2vw] h-[2vh] sm:text-[1.3vw] text-[1.3vh] text-green-500 bg-gray-800 w-full flex items-center justify-center">
        <span ref={foot}>&copy; 2024 Abd Ur Rehman Shafique. All rights reserved.</span>
      </div>
    </footer>
  );
}
