import { useGSAP } from "@gsap/react";
import SplitType from "split-type";
import gsap from "gsap";

function About() {
    useGSAP(()=>{
        const text = new SplitType('.taxi')
        const text2 = new SplitType('.tase')
        const chars = text.chars
        const chars2 = text2.chars
        const tl = gsap.timeline({repeat: -1})
        const tl2 = gsap.timeline({repeat: -1})
        tl2.to(chars2,{
            scale: 1.3,
            stagger: 0.1,
            duration: 0.8
        })
        tl2.to(chars2,{
            scale: 1,
            stagger: 0.1,
            duration: 0.8
        })
        tl.addLabel('start',0)
        tl.to(chars.slice(22,41),{
            color: 'green',
            transformOrigin: 'center center',
            rotationX: 360,
            stagger: 0.1,
            duration: 1
        }, 'start')
        tl.to(chars.slice(52,60),{
            color: 'green',
            transformOrigin: 'center center',
            rotationX: 360,
            stagger: 0.1,
            duration: 1
        })
        tl.to(chars.slice(166,178),{
            color: 'green',
            transformOrigin: 'center center',
            rotationX: 360,
            stagger: 0.1,
            duration: 1
        })
        tl.to(chars.slice(254,268),{
            color: 'green',
            transformOrigin: 'center center',
            rotationX: 360,
            stagger: 0.1,
            duration: 1
        })
        tl.to(chars.slice(625,632),{
            color: 'green',
            transformOrigin: 'center center',
            rotationX: 360,
            stagger: 0.1,
            duration: 1
        })
        tl.to(chars.slice(671,chars.length),{
            color: 'green',
            transformOrigin: 'center center',
            rotationX: 360,
            stagger: 0.05,
            duration: 0.8
        })//-----------------------------------------------------------------
        tl.to(chars.slice(22,41),{
            color: 'red',
            transformOrigin: 'center center',
            rotationX: -360,
            stagger: 0.1,
            duration: 1
        })
        tl.to(chars.slice(52,60),{
            color: 'red',
            transformOrigin: 'center center',
            rotationX: -360,
            stagger: 0.1,
            duration: 1
        })
        tl.to(chars.slice(166,178),{
            color: 'red',
            transformOrigin: 'center center',
            rotationX: -360,
            stagger: 0.1,
            duration: 1
        })
        tl.to(chars.slice(254,268),{
            color: 'red',
            transformOrigin: 'center center',
            rotationX: -360,
            stagger: 0.1,
            duration: 1
        })
        tl.to(chars.slice(625,632),{
            color: 'red',
            transformOrigin: 'center center',
            rotationX: -360,
            stagger: 0.1,
            duration: 1
        })
        tl.to(chars.slice(671,chars.length),{
            color: 'red',
            transformOrigin: 'center center',
            rotationX: -360,
            stagger: 0.05,
            duration: 0.8
        })
    })
    return (
        <div className="h-screen w-screen flex justify-center items-center cursor-cc">
            <div className="sm:w-[95vw] sm:h-[90vh] h-screen w-screen sm:rounded-[2vw] shadow-xl bg-p flex flex-col items-center sm:p-[15vh] p-[5vh] sm:space-y-[11vh] space-y-[11vw]  overflow-auto scrollbar-hide max-h-full">
                <img 
                    src="hello.jpg" 
                    alt="pic"
                    className="sm:h-[20vw] h-[40vh] rounded-[10vw] hover:outline-green-500 hover:outline-[0.25vw] outline-[0vw] transition-all duration-150"
                />
                <div className="font-Bondoni sm:text-[5vw] text-[10vw] text-pretty text-white tase text-center">Abd-ur-Rehman Shafique</div>
                <div className="text-white font-a sm:text-[2.4vw] text-[5vw] text-pretty taxi align-middle text-center sm:text-start">
                    I am a dedicated student of Software Engineering hailing from Pakistan, with a deep passion for technology and innovation. My journey in the world of tech is driven by a strong aspiration to become an entrepreneur, fueled by my keen interest in cutting-edge fields like machine learning, generative AI, web development, cybersecurity, blockchain, and networking.
                    These diverse areas not only fascinate me but also inspire me to continually expand my knowledge and skills, as I work towards creating impactful solutions in the tech industry. I am particularly excited about how these technologies are shaping the future and the potential they hold for solving complex problems.
                    Beyond my academic and professional pursuits, I have a range of hobbies that keep my curiosity alive and my mind sharp. I am an avid reader, constantly exploring new ideas and perspectives through books. Calligraphy is another passion of mine, where I find solace in the art of beautiful writing. Chess challenges my strategic thinking, while my love for physics and mathematics drives me to understand the fundamental principles that govern our world.
                    With a strong foundation in software engineering and a broad spectrum of interests, I am committed to pursuing excellence in my field and contributing to the tech community in meaningful ways.
                </div>
            </div>
        </div>
    );
}

export default About;
