import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Resources from './Brand';
import Error from './Error';
import Splash from './splash';
import About from './About';
import React from 'react';
import New from './New';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index path='/' element={<Splash />} />
        <Route path='home' element={<New />} />
        <Route path='about' element={<About />} />
        <Route path='resources' element={<Resources />}/>
        <Route path='/*' element={<Error />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
