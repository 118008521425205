import React, { useEffect } from 'react';
import SplitType from 'split-type';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollTrigger, useGSAP);

export default function Animation() {
  const scroll = 360;
  useGSAP(() => {
    gsap.set('.exclaim', {
      perspective: 400
    })
    const t = gsap.timeline();
  
    t.from('.exclaim', {
      duration: 1.5,   // Duration for the scaling animation
      opacity: 0,
      rotationZ: 360,
      repeat: -1,
      ease: 'back'
       // Scale back to original size
    });
  });
  
  
  
  useEffect(() => {
    // Initialize SplitType only after the component is mounted
    const text = new SplitType('.nam');
    const tl = gsap.timeline();
    const chars = text.chars;
    if (chars.length > 0) {
      gsap.set(".nam", { perspective: 400 });
      tl.from(chars, {
        scrollTrigger: {
          trigger: '.nam',
          start: 'top 80%',
          end: 'top 40%',
        },
        duration: 0.8,
        opacity: 0,
        scale: 0,
        y: 80,
        rotationX: 180,
        transformOrigin: "0% 50% -50",
        ease: "back",
        stagger: 0.01
      });
    }
  }, []);
  useGSAP(() => {
    const tween = gsap.to('.cards',{
      x: `-${scroll}vw`,
      duration: 7,
      ease: 'none',
    })
    ScrollTrigger.create({
        trigger: '.cards',
        start: 'top top',
        end: '+=300%',
        scrub: 2,
        animation: tween,
        pin: true
      }
    )
  })
  useGSAP(()=>{
    gsap.from('.ychar',{
      y: -10,
      rotationZ: 180,
      opacity: 0,
      duration: 1.5,
      ease: 'back',
      scrollTrigger: {
        trigger: '.cards',
        start: 'top-=100 top'
      }
    })
    gsap.set('.schar',{
      perspective: 400
    })
    gsap.from('.schar',{
      rotationX: 180,
      opacity: 0,
      duration: 1.5,
      ease: 'back',
      scrollTrigger: {
        trigger: '.cards',
        start: 'top-=200 top'
      }
    })
    gsap.from('.ochar', {
      y: -200,
      scaleY: 2,
      duration: 1,
      ease: 'linear',
      scrollTrigger:{
        trigger: '.ochar',
        start: 'top+=75% top',
        end: '+=60%',
        scrub: true,
      }
    })
    gsap.from('.ochar1', {
      y: +200,
      scaleY: 0.2,
      duration: 1,
      ease: 'none',
      scrollTrigger:{
        trigger: '.ochar1',
        start: 'top+=20% top',
        end: '+=60%',
        scrub: true,
      }
    })
    gsap.from('.achar', {
      y: 500,
      duration: 1.5,
      ease: 'back',
      scrollTrigger:{
        trigger: '.cards',
        start: 'top-=300 top',
        end: '+=400'
      }
    })
  })
  return (
    <div className=''>
      <div></div>
      <div style={{width: `calc(100vw + ${scroll}vw)`}} className={`pr-[10vw] cards overflow-hidden flex flex-row items-center bg-black text-white justify-center sm:h-[100vh]`}>
        <div className='sm:text-[40vw] flex flex-row'>
          <div className='schar'>S</div><div className='achar'>a</div><div className='ychar'>y&nbsp;</div><div> Hell</div><div className='ochar'>o</div><div>&nbsp;t</div><div className='ochar1'>o&nbsp;</div><div> the Future</div>
          <div className='exclaim'>!</div>
        </div>
      </div>
    </div>
  );
}