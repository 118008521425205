function Resources() {
    return (
        <div className="cursor-cc h-screen w-screen flex items-center justify-center">
            <div className="sm:h-[90vh] sm:w-[95vw] h-full w-screen bg-p shadow-xl  text-center sm:p-[15vh] flex-col sm:rounded-[2vw] text-white flex items-center justify-center sm:space-y-[6vw] space-y-[15vw]">
                <div>
                    <div className="sm:text-[7vw] text-[16vw] font-e">Brand Resources:</div>
                    <div className="sm:text-[1.3vw] text-[3vw]">You can download all the major brand assets here.</div>
                </div>
                <a href={`${process.env.PUBLIC_URL}/download.zip`}>
                    <button className="bg-green-500 transition-all duration-300 text-p px-[1.2vw] sm:w-[10vw] w-[25vw] rounded-[0.5vw] outline outline-green-400 outline-[0.3vw] sm:text-[2.3vw] text-[7vw] hover:outline-[0.2vw] hover:outline-white hover:bg-p hover:text-white font-q active:w-[9vw]">Download</button>
                </a>
            </div>
        </div>
    );
}

export default Resources;