import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

function LinkTo(props) {
    const [width, setWidth] = useState(null);
    const box = useRef()
    const [isHovered, setIsHovered] = useState(false);
    const opacityClass = isHovered ? 'opacity-100' : 'opacity-0';

    const handleMouseEnter = () => {
        setIsHovered(true);
        // Additional logic when hovering
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        // Additional logic when hovering stops
    };
    useEffect(() => {
        const updateWidth = () => {
            if (box.current) {
                const boundingRect = box.current.getBoundingClientRect();
                setWidth(boundingRect.width);
            }
        };
    
        updateWidth(); // Set initial width
        window.addEventListener('resize', updateWidth); // Update width on window resize
    
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);
    if(props.num === 1){
        return (
            <a href={`mailto:${props.email}?subject=${encodeURIComponent(props.subject)}`} className='h-[10vh] flex items-center hover:cursor-custom transition-transform duration-1000' onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
                <div ref={box}>{props.text}</div>
                <div className={`h-[0.4vh] absolute bottom-0 bg-white rounded-[0.5vh] ${opacityClass} transition-opacity duration-500`} style={{ width: `${width}px` }}></div>
            </a>
        );
    }
    else{
        return (
            <Link to={ props.dest } className='h-[10vh] flex items-center hover:cursor-custom transition-transform duration-1000' onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <div ref={box} className=''>{props.text}</div>
                <div className={`h-[0.4vh] absolute bottom-0 bg-white rounded-[0.5vh] ${opacityClass} transition-opacity duration-500`} style={{ width: `${width}px` }}></div>
            </Link>
        );
    }
}

export default LinkTo;