import SplitType from "split-type";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP)

function Error() {
    useGSAP(()=>{
        const text = new SplitType('.rex')
        const chars = text.chars
        const tl = gsap.timeline({repeat: -1})
        tl.addLabel('start',0)
        tl.to(chars,{
            color: '#A0A0A0',
            duration: 0.8,
            stagger: 0.2
        })
        tl.to(chars,{
            color: 'white',
            duration: 0.8,
            stagger: 0.2
        },'start+=1.2')
    })
    return (
        <div className="cursor-cc text-[10vh] font-e bg-black text-white font-bold w-[90vw] h-[90vh] mx-[5vw] my-[5vh] rounded-[5vh] flex flex-col justify-center items-center">
            <div className="rex ">Error: 404 </div><div className="rex">No Such Page Exists.</div>
        </div>
    );
}

export default Error;