import { useGSAP } from "@gsap/react";
import gsap from "gsap";

gsap.registerPlugin(useGSAP);

export default function App(){
  useGSAP(
    () => {
      gsap.from('.name',{
        opacity: 0,
        transformOrigin: 'center center',
        scale: 0,
        duration: 2, 
        ease: 'power4.out'
      })
    }
  );
  return(
    <div className="w-full">
      <div className={`sm:font-b font-cr font-black absolute inset-0 bg-opacity-50 bg-black rounded-3xl ml-[1vw] mr-[1vw] mb-[1vw] sm:mt-[calc(7vw-1vw)] mt-[8vh] text-[#ffffff] p-10 flex flex-col items-center justify-center `}>
        <h1 className="name sm:text-[7.5vw] text-[10vw]">Abd-ur-Rehman</h1>
        <h1 className="name sm:text-[7.5vw] text-[10vw]">Shafique</h1>
      </div>
      {/* <div className="bg-black h-[6vh] w-full"></div> */}
      <img src="/p1new.png" alt="a programmer" className="h-screen w-full hidden sm:block"/>
      <img src="/p1.png" alt="a programmer" className="h-[93vh] w-full block sm:hidden"/>
    </div>
  )
}