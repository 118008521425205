import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger)

function Info() {
    useGSAP(()=>{
        const split = new SplitType('.infot');
        const chars = split.chars;
        const tl = gsap.timeline({scrollTrigger: {trigger: '.infot', start: 'top top+=60%'}})
        const ttt = gsap.timeline({scrollTrigger: {trigger: '.info', start: 'top top+=65%'}})
        ttt.addLabel('start',0)
        ttt.from('.titl',{
            opacity: 0
        },"-=0.2")
        gsap.set(chars, {perspective:400})
        tl.from(chars, {
            opacity: 0,
            stagger: 0.02,
            transformOrigin: "center center",
            duration: 0.8,
            rotationX: -360,
        })
        tl.to(chars.slice(4,23), {
            color: '#22c55e',
            stagger: 0.04,
            transformOrigin: "center center",
            duration: 0.8,
            rotationX: 360,
        })
        tl.to(chars.slice(32,36), {
            color: '#22c55e',
            stagger: 0.04,
            transformOrigin: "center center",
            duration: 0.8,
            rotationX: 360,
        })
        tl.to(chars.slice(54,63), {
            color: '#22c55e',
            stagger: 0.04,
            transformOrigin: "center center",
            duration: 0.8,
            rotationX: 360,
        })
        tl.to(chars.slice(130, 155), {
            color: '#22c55e',
            stagger: 0.04,
            transformOrigin: "center center",
            duration: 0.8,
            rotationX: 360,
        })
        tl.to(chars.slice(210,220), {
            color: '#22c55e',
            stagger: 0.04,
            transformOrigin: "center center",
            duration: 0.8,
            rotationX: 360,
        })
        return ()=>{
            ttt.kill()
        }
    })
    return (
        <div className="text-white text-[6vh] px-[7vw] py-[10vh] flex flex-col justify-center font-e">
            <div className=" flex flex-row info">
                <div className="text-[12vh] brack1">[</div><div className="h-[9vh] mt-[6.5vh] titl">Basic Info</div><div className="text-[12vh] brack">]</div>
            </div>
            <div className="infot text-pretty sm:text-[6vh] text-[7vw] overflow-hidden pt-[5vh] mb-[6.5vh]">I'm a Software Engineering student at NUST with a strong focus on MERN Stack Development. I thrive on solving complex problems and enjoy creating dynamic, responsive web applications. I'm passionate about technology and always eager to embrace new challenges that push the boundaries of my skills.</div>
        </div>
    );
}

export default Info;