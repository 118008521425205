import { Canvas} from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import React from 'react';

const Model = ({ ModelPath, position }) => {
  const ref = useRef();
  const { scene } = useGLTF(ModelPath);

  useEffect(() => {
    if (ref.current) {
      const ti = gsap.timeline({
        repeat: -1,
        scrollTrigger:{
          trigger: '.model',
          start: 'top bottom',
        }
      })
      ti.to(ref.current.rotation, {
        z: (1/4)*Math.PI,       // Change z position to 0
        duration: 1,   // Animation duration in seconds
        ease: "linear", // Animation easing
      });
      ti.to(ref.current.rotation, {
        z: (-1/4)*Math.PI,         // Change z position to 0
        duration: 1,   // Animation duration in seconds
        ease: "linear", // Animation easing
      })
      ti.to(ref.current.rotation, {
        z: 0,         // Change z position to 0
        duration: 1,   // Animation duration in seconds
        ease: "linear", // Animation easing
      });
      ti.to(ref.current.rotation, {
        x: (1/4)*Math.PI,        // Change z position to 0
        duration: 1,   // Animation duration in seconds
        ease: "power1.inOu1", // Animation easing
      });
      ti.to(ref.current.rotation, {
        x: 0,        // Change z position to 0
        duration: 1,   // Animation duration in seconds
        ease: "power1.inOu1", // Animation easing
      });
    }
  }, [ModelPath]);

  return <primitive ref={ref} object={scene} position={position} scale={1.6} />;
};

function MyThree({ModelPath}) {
  return ( 
    <div className="sm:size-[10vh] size-[7vh] model">
      <Canvas fallback={<div className='text-green-500'>Loading 3D Model...</div>}>
        <ambientLight intensity={2} />
        <Model ModelPath={ModelPath} position={[0, 0, 0]}/>
        {/* <OrbitControls enableZoom={false} enablePan={false} enableRotate={true}/> */}
      </Canvas>
    </div>
   );
}

export default MyThree;