import MyThree from './three'
import LinkTo from './LinkTo';
import { Link } from 'react-router-dom';
import { useState } from 'react';

export default function Navigation(){
    const ModelPath = '/cute.glb'
    const email = 'shafiqueabdurrehman1@gmail.com';
    const subject = 'Web Development';
    const [show, setShow] = useState(false);
    const handleClick = ()=>{
        setShow(!show);
    }
    return(
        <div className="w-full flex font-medium items-center sm:h-[10vh] h-[7vh] bg-black justify-center flex-row space-x-[3.5vw] text-[1.4vw] text-white bg-opacity-60 backdrop-filter backdrop-blur-lg">
            <Link to={"/"} className="hover:cursor-custom left-0 absolute flex flex-row items-center font-q">
                <div>
                    <MyThree ModelPath={ModelPath} />
                </div>
                <div className='sm:block hidden text-[2.8vw] hover:text-[2.9vw] transition-all duration-300'>Abd-ur-Rehman</div>
            </Link>
            <div className='sm:hidden block text-[14vw] transition-all duration-300 font-q'>Abd-ur-Rehman</div>
            <img src="/menuIcon.png" alt="menu" onClick={handleClick} className={`sm:hidden block h-[5vh] duration-200 hover:cursor-custom border-[0.7vh] active:h-[5.1vh] border-black rounded-[1.4vh] absolute right-[1vh] transition-transform ${show?'hidden':''}`} /> 
            <div className='sm:block hidden flex-row '>
                <div className='flex flex-row space-x-[3.5vw] text-[1.4vw] text-white'>
                    <LinkTo dest={'/home'} text={'Home'} num={2}/>
                    <LinkTo dest={'/about'} text={'About'} num={2}/>
                    <LinkTo dest={'/resources'} text={'Resources'} num={2}/>
                    <LinkTo subject={subject} email={email} text={'Contact'} num={1}/>
                </div>
            </div>
            <div className={`absolute bg-opacity-60 backdrop-filter backdrop-blur-lg text-white h-screen w-[70vw] sm:hidden block bg-black right-0 p-[3vh] top-0 ${show? '-translate-x-[0vw]' :'translate-x-[70vw]'} transition-transform duration-700`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" onClick={handleClick} height={'100%'} width={'100%'} className="size-[5vh] top-0 left-0">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
                <div className='flex flex-col space-y-[2vh] text-[6vh] items-center justify-between text-green-500 font-q'>
                    <LinkTo dest={'/home'} text={'Home'} num={2}/>
                    <LinkTo dest={'/about'} text={'About'} num={2}/>
                    <LinkTo dest={'/resources'} text={'Resources'} num={2}/>
                    <LinkTo subject={subject} email={email} text={'Contact'} num={1}/>
                </div>
            </div>
        </div>
    );
}
