import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import SplitType from "split-type";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

function Quote() {
    useGSAP(()=>{
        const text = new SplitType('.quotation')
        gsap.from(text.lines,{
            stagger: 0.4,
            opacity: 0,
            scale: 0,
            duration: 0.7,
            scrollTrigger:{
                trigger: '.quotation',
                start: 'top top+=80%'
            }
        })
    })
    return (
        <div className="sm:hidden bg-black text-white w-screen h-[40vh] text-[8vh] flex items-center justify-center p-[10vw]">
            <div className="quotation">"Think Different!"</div>
        </div>
    );
}

export default Quote;