import { useState } from "react";

const Fire = ({mousePosition, setMousePosition}) => {
    const [isMouseOver, setIsMouseOver] = useState(false);
    const handleMouseLeave = () => {
        setIsMouseOver(false);
    };
    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
        const x = ((e.clientX - left) / width) * 100;
        const y = ((e.clientY - top) / height) * 100;
        setMousePosition({ x, y });
        setIsMouseOver(true);
    };
    return ( 
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={0.13}
            stroke="currentColor"
            className="stroke-gray-700"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            width={'100%'}
            height={'100%'}
        >
            <defs>
                <radialGradient id="dynamicRadialGradient" cx={`${mousePosition.x}%`} cy={`${mousePosition.y}%`} r="60%">
                    <stop offset="0%" stopColor="#0bf015" />
                    <stop offset="100%" stopColor="#0D0D0D" />
                </radialGradient>
            </defs>
            <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                d="M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z" 
                stroke={isMouseOver ? "url(#dynamicRadialGradient)" : "#0D0D0D"} 
                fill="#0D0D0D"
            />
            <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                d="M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z" 
                stroke={isMouseOver ? "url(#dynamicRadialGradient)" : "#0D0D0D"} 
                fill="#0D0D0D"
            />
        </svg>

     );
}

export default Fire;  