import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger)

function WhyChoose() {
    useGSAP(()=>{
        const text = new SplitType('.write');
        const text1 = new SplitType('.tex')
        const chars = text.chars;
        const lines = text1.lines;
        const tl = gsap.timeline({
            scrollTrigger:{
            trigger: '.write',
            start: 'top top+=80%',
        }})
        tl.addLabel('start',0)
        tl.from('.br1',{
            opacity:0,
            duration: 0.5
        },'start')
        tl.from('.br2',{
            opacity:0,
            x: '-25vw',
            duration: 1
        },'start')
        chars.forEach((el, index)=>{
            tl.from(el,{
                y: index % 2 === 0 ? "-100%" : "100%",
                duration: 0.2,
                stagger: 0.01,
                opacity: 0,
                ease: 'back'
            })
        })
        tl.addLabel("cont")
        tl.from('.quest',{
            transformOrigin: 'bottom center',
            rotationZ: 360,
            duration:2,
            opacity: 0,
            // repeat:-1,
            ease: 'power3.inOut'
        })
        tl.to('.oo',{
            scale: 1.7,
            marginRight: '1vw',
            marginLeft: '1vw',
            duration: 0.5
        })
        tl.to('.oo',{
            scale: 1,
            marginRight: '0vh',
            marginLeft: '0vh',
            duration: 0.5,
        })
        tl.from(lines,{
            stagger: 1,
            opacity: 0,
            x: '-50vw',
            duration: 1.2,
            y: '5vw',
            ease: 'back'
        },'cont')
        gsap.set(text1.chars.slice(74,76),{perspective: 400})
        const t = gsap.timeline({repeat: -1})
        tl.add(t)
        t.to(text1.chars.slice(73,75),{
            rotationY: 360,
            transformOrigin: 'center center',
            color: 'green',
            duration: 3,
            stagger: 0.1,
            ease: 'power4.out'
        })
        t.addLabel('a')
        t.to(text1.chars.slice(73,75),{
            rotationX: 360,
            transformOrigin: 'center center',
            color: 'white',
            duration: 3,
            stagger: 0.1,
            ease: 'power4.out'
        })
        t.to(text1.chars.slice(89,107),{
            color: 'green',
            duration: 2,
            stagger: 0.1,
            ease: 'power4.out'
        },"a")
        t.to(text1.chars.slice(89,107),{
            color: 'white',
            duration: 2,
            stagger: 0.1,
            ease: 'power4.out'
        },"a+=2.1")
    })
    return (
        <div className="text-white overflow-hidden sm:text-[6vh] text-[9vw] px-[7vw] py-[10vh] flex flex-col justify-center font-e">
            <div className=" flex flex-row">
                <div className="text-[12vh] br1">[</div><div className="h-[9vh] mt-[6.5vh] write">Why Ch</div><div className="h-[9vh] mt-[6.5vh] write oo">oo</div><div className="h-[9vh] mt-[6.5vh] write">se Me</div><div className="h-[9vh] mt-[6.5vh] quest">?</div><div className="text-[12vh] br2">]</div>
            </div>
            <div className="text-pretty text-center sm:text-start pt-[5vh] mb-[6.5vh] tex  sm:text-[6vh] text-[7vw]">As a passionate web developer, I specialize in creating websites that offer immersive 3D experiences and stunning animations. I bring a unique blend of creativity and technical skill to each project, ensuring your website stands out and engages your audience. When you choose me, you're not just hiring a developer—you're partnering with someone dedicated to bringing your vision to life with precision and flair.</div>
        </div>
    );
}

export default WhyChoose;